// scss
import './src/styles/style.scss';

import { ActionContextProvider, createConfig } from './src/cra';

import { Helmet } from 'react-helmet';
import React from 'react';

export const wrapRootElement = ({ element }) => {
  createConfig({
    website: 'Medicare FAQ',
  });
  return (
    <ActionContextProvider>
      <Helmet>
        <script
          async
          defer
          id='demio-js'
          type='text/javascript'
          src='https://cdn.demio.com/production/dashboard/embed.bundle.js'
        ></script>
      </Helmet>
      {element}
    </ActionContextProvider>
  );
};
